import Oidc from 'oidc-client';

var mgr = new Oidc.UserManager({
  authority: process.env.VUE_APP_AUTHORITY,
  client_id: 'userManager',
  redirect_uri: process.env.VUE_APP_CLIENT_URI + 'callback',
  response_type: 'id_token token',
  scope: 'openid profile omniapi',
  post_logout_redirect_uri: process.env.VUE_APP_CLIENT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.VUE_APP_CLIENT_URI + 'static/silent-renew.html',
  accessTokenExpiringNotificationTime: 10,
})

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;



export default mgr;
