<template>
  <div>
    <div>This is a test</div>
    <v-btn-toggle v-if="isAdmin" v-model="view" mandatory color="primary">
      <v-btn>User Manager</v-btn>
      <v-btn>App Manager</v-btn>
    </v-btn-toggle>
    <users-table v-show="view == 0"></users-table>
    <clients-table v-show="view == 1 && isAdmin"></clients-table>
  </div>
</template>
<script>
import UsersTable from "../UserManager/users-table";
import ClientsTable from "../ClientManager/clients-table";
export default {
  components: {
    UsersTable,
    ClientsTable,
  },
  data() {
    return {
      view: 0,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
  },
};
</script>
